<template>
    <v-expansion-panels v-model="panel">
        <v-expansion-panel expand
  v-model="panel">
            <v-expansion-panel-header>
                <h3 style="color:#083C5F">Jugadores</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Nombre de Jugador
                                </th>
                                <th class="text-left">
                                    Número
                                </th>
                                <th class="text-left">
                                    Posicion
                                </th>
                                <th class="text-left">
                                    Foto
                                </th>
                                <th class="text-left">
                                    Accion
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in players" :key="index">
                                <td>
                                    <v-text-field label="" v-model="item.full_name" outlined dense>
                                    </v-text-field>
                                </td>
                                <td>
                                    <v-text-field label="" v-model="item.number" outlined dense>
                                    </v-text-field>
                                </td>
                                <td>
                                    <v-select v-model="item.player_position_id" :items="positions" item-value="id"
                                        item-text="name" label="" outlined dense />
                                </td>
                                <td>
                                    <InputPhotoUpload :item="item"> </InputPhotoUpload>
                                </td>
                                <td class="d-flex justify-center">
                                    <v-btn icon color="red" @click="deletePlayer(index)">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <v-row class="d-flex justify-end">
                    <v-btn @click="newPlayer()" class="ma-3 rounded-lg text-capitalize" dense color="primary">
                        Agregar Jugador
                    </v-btn>

                </v-row>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
// import FotoUpload from "@/components/util/Foto.vue";
import { mapState, mapActions } from "vuex";
import InputPhotoUpload from "../util/InputPhotoUpload.vue";
// import { required } from "vuelidate/lib/validators";
// import Files from "@/components/util/File.vue";

export default {
    name: "form-player",
    props: ["players"],
    components: { InputPhotoUpload },
    watch: {},
    data: () => ({
        panel: 0,
    }),
    computed: {
        ...mapState({
            positions: (state) => state.util.positions,
        }),
    },
    methods: {
        ...mapActions(["fetchPositions"]),
        getFetch() {
            this.fetchPositions();
        },
        newPlayer() {
            this.players.push({
                full_name: "",
                number: "",
                player_position_id: "",
                photo_url: "",
            });
        },
        deletePlayer(index) {
            this.players.splice(index, 1);
        },
    },
    created() {
        this.getFetch();
    },
};
</script>

<style scoped lang="scss">
tbody {
    tr:hover {
        background-color: transparent !important;
    }
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child,
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:last-child,
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row),
.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    border-bottom: none !important
}


/* .row {
    margin-top: -10px !important;
} */
</style>