<template>
  <v-container fluid>
    <v-col col="12" md="12" lg="12" outlined>
      <v-card class="pa-4 rounded-xl">
        <v-card-title class="justify-left d-flex flex-row pb-1">
          <p>Equipo</p>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider color="#75D89F" class="mx-3"></v-divider>
        <v-card-text>
          <h3 style="color:#083C5F" class="pb-4">Información del Equipo</h3>
          <v-row>
            <v-col col="12">
              <form-team :team="iteam"></form-team>
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <form-player :players="iteam.players"></form-player>
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <form-statistic :team="iteam"></form-statistic>
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <form-last-games :team_matches="iteam.team_matches" :name="iteam.name"></form-last-games >
            </v-col>
          </v-row>
          <v-row class="d-flex justify-end">
            <v-btn @click="save()" class="ma-3 rounded-lg text-capitalize" dense color="primary">
             Grabar
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-snackbar v-model="snackbar" :color="color" class="mr-2" top>
      <span class="d-flex justify-center white--text">{{ text }}</span>
    </v-snackbar>
  </v-container>
</template>



<script>
import FormTeam from "@/components/forms/FormTeam.vue";
import FormPlayer from '@/components/forms/FormPlayer.vue';
import FormStatistic from '@/components/forms/FormStatistic.vue';
import FormLastGames from "@/components/forms/FormLastGames.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import _cloneDeep from 'lodash/cloneDeep';

export default {
  name: "TeamAddEdit",
  props: ["id"],
  components: {
    FormTeam,
    FormPlayer,
    FormStatistic,
    FormLastGames
  },
  watch: {
    team: function (value) {
      if (this.id) {
        this.iteam =_cloneDeep(value);
      }
    },
    teamSusccesMessage: function (value) {
      if (value) {
        this.mensaje(true, "success", this.teamSusccesMessage, 5000);
        if (!this.id) this.iteam = {};
        this.$router.go(-1);
      }
    },
    teamError: function (value) {
      if (value)
        this.mensaje(true, "error", this.teamError.message, 5000);
    },

  },
  computed: {
    ...mapState({
      team: (state) => state.team.team,
      teamError: (state) => state.team.teamError,
      teamSusccesMessage: (state) => state.team.teamSusccesMessage,
    }),
  },
  data: () => ({
    iteam: {
      players: [],
      team_matches: []
    },
    snackbar: false,
    color: "",
    text: "",
  }),
  methods: {
    ...mapActions(["addTeam", "editTeam", "getTeamById","setTeamEmpty"]),
    save() {
      this.removeTeamMatchesEmpty();
      if (this.id) {
        //update
        this.editTeam({ team: this.iteam });
      } else {
        this.addTeam({ team: this.iteam });
        //create
      }
    },
    removeTeamMatchesEmpty() {
      this.iteam.team_matches = this.iteam.team_matches.filter(
        (item) => item.second_team_name !== ""
      );
    },
    mensaje(snackbar, color, text) {
      this.snackbar = snackbar;
      this.color = color;
      this.text = text;
    },
    getFetch() {
      // console.log(this.team)
      if (this.id) {
        this.getTeamById({ id: this.id });
      }else{
        this.setTeamEmpty();
      }
    },
  },
  created() {
    this.getFetch();
  },
};
</script>

<style scoped>
</style>