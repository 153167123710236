<template>
    <v-expansion-panels v-model="panel">
        <v-expansion-panel expand v-model="panel">
            <v-expansion-panel-header>
                <h3 style="color:#083C5F">Ultimos 5 partidos</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Equipo
                                </th>
                                <th class="text-left">
                                    Goles
                                </th>
                                <th class="text-left">

                                </th>
                                <th class="text-left">
                                    Equipo 2
                                </th>
                                <th class="text-left">
                                    Goles
                                </th>
                                <th class="text-left">
                                    Competencia
                                </th>
                                <!-- <th class="text-left">
                                    Accion
                                </th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in team_matches" :key="index">
                                <td>
                                    <v-subheader>{{ name }}</v-subheader>
                                </td>
                                <td>
                                    <v-text-field label="" v-model="item.team_goals" outlined dense>
                                    </v-text-field>
                                </td>
                                <td>
                                    <v-subheader>vs</v-subheader>
                                </td>
                                <td>
                                    <v-text-field label="" v-model="item.second_team_name" outlined dense>
                                    </v-text-field>
                                </td>
                                <td>
                                    <v-text-field label="" v-model="item.second_team_goals" outlined dense>
                                    </v-text-field>
                                </td>
                                <td>
                                    <v-text-field label="" v-model="item.competition" outlined dense>
                                    </v-text-field>
                                </td>
                                <td class="d-flex justify-center">
                                    <v-btn icon color="red" @click="deleteTeamMatch(index)">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <v-row class="d-flex justify-end">
                    <v-btn @click="newTeamMatch()" class="ma-3 rounded-lg text-capitalize" dense color="primary">
                        Agregar Partido
                    </v-btn>

                </v-row>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
    name: "form-last-games",
    props: ["team_matches",
        "name"
    ],
    watch: {

    },
    // components: { InputPhotoUpload },
    data: () => ({
        panel: 0,
        maxVal: 5
    }),
    computed: {
        ...mapState({
            selectTeams: (state) => state.team.selectTeams,
        }),
        // getTeamMatches(){
        //    return this.returnMatches();
        // }
    },
    methods: {
        ...mapActions(["fetchAllTeams"]),
        getFetch() {
            this.fetchAllTeams();
        },
        newTeamMatch() {
            if (this.team_matches.length < this.maxVal) {
                this.team_matches.push({
                    team_goals: "",
                    second_team_name: "",
                    second_team_goals: "",
                    competition: ""
                });
            }
        },
        deleteTeamMatch(index) {
            this.team_matches.splice(index, 1);
        },
        refatctorTeamMatches(matches) {
            if (matches.length < this.maxVal) {
                const lnM = matches.length
                for (let i = 0; i < this.maxVal - lnM; i++) {
                    matches.push({
                        key: i,
                        team_goals: "",
                        second_team_name: "",
                        second_team_goals: "",
                        competition: ""
                    });
                }
            }
        }

    },
    created() {
        this.getFetch();
        this.refatctorTeamMatches(this.team_matches)

    },
};
</script>

<style scoped lang="scss">
tbody {
    tr:hover {
        background-color: transparent !important;
    }
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child,
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:last-child,
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row),
.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    border-bottom: none !important
}


/* .row {
    margin-top: -10px !important;
} */
</style>