<template>
    <v-expansion-panels v-model="panel">
        <v-expansion-panel expand v-model="panel">
            <v-expansion-panel-header>
                <h3 style="color:#083C5F">Estadísticas</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-row>
                    <v-col cols="6">
                        <v-row>
                            <v-col cols="3">
                                <v-subheader class="d-flex justify-end">Partidos ganados</v-subheader>
                            </v-col>
                            <v-col cols="9">
                                <v-text-field label="" v-model="team.games_won" required outlined dense></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-row>
                            <v-col cols="3">
                                <v-subheader class="d-flex justify-end">Partidos Perdidos</v-subheader>
                            </v-col>
                            <v-col cols="9">
                                <v-text-field label="" v-model="team.games_lost" required outlined dense></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-row>
                            <v-col cols="3">
                                <v-subheader class="d-flex justify-end">Partidos Empatados</v-subheader>
                            </v-col>
                            <v-col cols="9">
                                <v-text-field label="" v-model="team.games_tied" required outlined dense></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                 <v-row>
                    <v-col cols="6">
                        <v-row>
                            <v-col cols="3">
                                <v-subheader class="d-flex justify-end">Goles</v-subheader>
                            </v-col>
                            <v-col cols="9">
                                <v-text-field label="" v-model="team.goals" required outlined dense></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>

import { mapState, mapActions } from "vuex";


export default {
    name: "form-statistic",
    props: ["team"],
    // components: { FotoUpload },
    watch: {},
    validations() {
        return {
            team: {
                // name: required,
                // fifa_position: required,
                // continent_id: required,
                // games_won: required,
                // games_lost: required,
                // games_tied: required,
                // goals: required,
                // players: required,
            },
        };
    },
    data: () => ({
        panel: 0,
    }),
    computed: {
    },
    methods: {
        getFetch() {
            // this.fetchPositions();
        },
    },
    created() {
        this.getFetch();
    },
};
</script>

<style scoped lang="scss">

</style>