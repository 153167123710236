<template>
    <v-container fluid>
        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">País</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-text-field label="" v-model="team.name" required outlined dense
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Continente</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-select v-model="team.continent_id" :items="continents" item-value="id" item-text="name"
                            label="" outlined dense />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Puesto FIFA</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-text-field label="" v-model="team.fifa_postion" required outlined dense></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Foto</v-subheader>
                    </v-col>
                    <v-col cols="9">
                       <InputPhotoUpload :item="team"  style="padding-top:3px">
                       </InputPhotoUpload>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import InputPhotoUpload from "@/components/util/InputPhotoUpload.vue";
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
// import Files from "@/components/util/File.vue";

export default {
    name: "form-team",
    props: ["team"],
    components: { InputPhotoUpload },
    watch: {},
    validations() {
        return {
            team: {
                name: required,
                fifa_position: required,
                continent_id: required,
                games_won: required,
                games_lost: required,
                games_tied: required,
                goals: required,
                players: required,
            },
        };
    },
    data: () => ({
        module: "team",
    }),
    computed: {
        ...mapState({
            continents: (state) => state.util.continents,
        }),
    },
    methods: {
        ...mapActions(["fetchContinents"]),
        getFetch() {
            this.fetchContinents();
        },
    },
    created() {
        this.getFetch();
    },
};
</script>

<style scoped>
/* .row {
    margin-top: -10px !important;
} */
</style>